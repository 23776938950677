<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Games from "@/services/Games";
  import Teams from "@/services/Teams";
  import _ from 'underscore';
  import ColumnVisibilityV2 from '@/components/ColumnVisibilityV2';

  export default {
    components: { Layout, PageHeader, ColumnVisibilityV2 },
    page: {
      title: "MDC Leaderboard",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    data() {
      return {
        title: "MDC Leaderboard",
        items: [
          {
            text: "Fan Experience",
            href: "/",
          },
          {
            text: "MDC Leaderboard",
            active: true,
          },
        ],
        showLoader:false,
        showLoader_init:false,
        leaderboardFullData: [],
        leaderboardType: 'season',
        leaderboardData: [],
        leaderboardTop3: [],
        matches: [],
        selectedMatch: '',
        isBusy: false,
        showMatchSelection: false,
        error: null,
        totalPages: 0,
        totalItems: 0,
        currentPage: 1,
        perPage: 10,
        filter: '',
        sortBy: "position",
        sortDesc: 'false',
        fields: [
          {
            value: "position",
            text: "Position",
            sortable: true,
            visible: true
          },
          {
            value: "score",
            text: "Score",
            sortable: true,
            visible: true
          },
          {
            value: "first_name",
            text: "Customer",
            sortable: true,
            visible: true
          },
          {
            value: "nickname",
            text: "Nickname",
            sortable: true,
            visible: true
          },

          {
            value: "participation",
            text: "Participation",
            sortable: true,
            visible: true
          },
          {
            value: "matches_played",
            text: "Matches Played",
            sortable: true,
            visible: true
          },
        ]
      };
    },
    middleware: "authentication",
    mounted() {
      this.getData()
    },
    watch: {
      filter: _.debounce(function () {
        // When the search input changes, load data from the backend
        this.currentPage = 1; // Reset page to 1 when searching
        this.getLeaderboardData(this.leaderboardType, this.selectedMatch)
      }, 1500),
    },
    methods: {

      async getLeaderboardData(type, type_id = '', limit = null) {
        try {
          this.showLoader_init = true;
          this.showLoader = true;

          if (this.selectedGameId === '' || type === '') {
            console.log("Missing required parameters");
            this.showLoader_init = false;
            this.showLoader = false;
            return false;
          }

          let data = `type=${type}&game_id=${this.selectedGameId}&type_id=${type_id}`;
          if (limit) {
            data += `&limit=${limit}`;
          }

          let filterData = this.setPaginationFilters(data);
          const response = await Games.getLeaderboardMini(filterData);
          this.leaderboardFullData = response.data;

          if (this.leaderboardFullData.leaderboard && this.leaderboardFullData.leaderboard.length > 0) {
            if (limit) {
              this.leaderboardTop3 = this.leaderboardFullData.leaderboard;
            } else {
              this.leaderboardData = this.leaderboardFullData.leaderboard;
              this.totalItems = this.leaderboardFullData.total_items;
              this.totalPages = this.leaderboardFullData.total_pages;

              // Update top 3 leaderboard
              this.leaderboardTop3 = this.leaderboardFullData.leaderboard.slice(0, 3);
            }
          } else {
            if (limit) {
              this.leaderboardTop3 = [];
            } else {
              this.leaderboardTop3 = [];
              this.leaderboardData = [];
            }
          }

          this.showLoader_init = false;
          this.showLoader = false;
        } catch (error) {
          console.error("Error fetching leaderboard data:", error);
          this.error = error.response && error.response.data && error.response.data.error
              ? error.response.data.error
              : "An unexpected error occurred.";
          this.showLoader_init = false;
          this.showLoader = false;
        }
      },

      async getMatches(type){
        try {
          this.isBusy = true
          const response = await Teams.getFixtures(type)
          if(response.data){
            let pastMatches = response.data;
            if(pastMatches != null && pastMatches.fixtures.length > 0){
              this.matches = pastMatches.fixtures;
            }else{
              this.matches = []
            }
          }
          this.isBusy = false
        } catch (error) {
          this.error = error.response.data.error ? error.response.data.error : "";
        }
      },
      formatNickname(nickname){
        return nickname ? nickname : 'N/A'
      },
      handlePaginationChange(){
        if(this.leaderboardType == 'season'){
          this.getLeaderboardData('season')
        }else if(this.leaderboardType == 'match'){
          this.getLeaderboardData('match', this.selectedMatch)
        }
      },
      async changeLeaderboard(){
        if(this.leaderboardType == 'season'){
          this.selectedMatch = ''
          this.showMatchSelection = false
          this.getData()
        }else if(this.leaderboardType == 'match'){
          this.resetData()
          this.showMatchSelection = true
          await this.getMatches('PAST')
        }
      },
      leaderboardTitle(){
        let title_addition = ''
        if(this.leaderboardType == 'season'){
          if(this.leaderboardFullData.type_id){
            title_addition = this.leaderboardFullData.type_id
          }
          return 'Season Leaderboard <span>'+title_addition+'</span>'
        }else{
          let match = this.matches.filter(match => match.match_id === this.selectedMatch);
          if(match.length == 1){
            title_addition = match[0].home_team_name + ' - ' + match[0].away_team_name
          }
          return 'Match Leaderboard <br /><span>'+title_addition+'</span>'
        }
      },
      resetData(){
        this.leaderboardTop3 = this.leaderboardFullData = this.leaderboardData = []
        this.error= null
        this.totalPages= 0
        this.totalItems= 0
        this.currentPage= 1
        this.perPage= 10
        this.filter= ''
      },
      getData(){
        this.resetData()
        // this.getTopLeaderboardData(this.leaderboardType, this.selectedMatch)
        this.getLeaderboardData(this.leaderboardType, this.selectedMatch)
      }
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="col-12 col-md-4 mx-auto">
            <label class="col-md-12 text-center col-form-label">Select Leaderboard Type</label>
            <select class="form-select" @change="changeLeaderboard()" id="selectLeaderboard" v-model="leaderboardType">
              <option value="season">Seasonal</option>
              <option value="match">Per Match</option>
            </select>
          </div>
          <div class="col-12 col-md-4 mx-auto mb-4" v-if="showMatchSelection">
            <label class="col-md-12 text-center col-form-label">Select Match</label>
            <select class="form-select" v-model="selectedMatch" id="selectMatch" :class="isBusy ? 'placeholder' :''" @change="getData">
              <option v-for="match in matches" :key="match.match_id" :value="match.match_id">
                {{match.home_team_name}} - {{match.away_team_name}} / <span class="ms-2">{{match.date_time}}</span>
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <h4 class="text-center mt-4" v-html="leaderboardTitle()"></h4>
          <div class="card-body" v-if="!showLoader_init">
            <div class="row" v-if="leaderboardTop3.length">
              <div class="col-md-12 text-center" v-if="leaderboardTop3[0]">
                <span class="font-size-28"><i class="mdi mdi-crown-outline text-gold"></i></span>
                <router-link title="View Customer" :to="{ path: `/customers/profile/${leaderboardTop3[0].customer_id}`}">
                  <h4>{{leaderboardTop3[0].first_name}} {{leaderboardTop3[0].middle_name}} {{leaderboardTop3[0].last_name}}</h4>
                </router-link>
                <p class="text-muted" v-html="formatNickname(leaderboardTop3[0].nickname)"></p>
                <h5>{{leaderboardTop3[0].score}}</h5>
              </div>
              <div class="col-md-6 text-center" v-if="leaderboardTop3[1]">
                <p class="badge rounded-circle bg-silver font-size-16">{{leaderboardTop3[1].position}}</p>
                <router-link title="View Customer" :to="{ path: `/customers/profile/${leaderboardTop3[1].customer_id}`}">
                  <h4>{{leaderboardTop3[1].first_name}} {{leaderboardTop3[1].middle_name}} {{leaderboardTop3[1].last_name}}</h4>
                </router-link>
                <p class="text-muted" v-html="formatNickname(leaderboardTop3[1].nickname)"></p>
                <h5>{{leaderboardTop3[1].score}}</h5>
              </div>
              <div class="col-md-6 text-center" v-if="leaderboardTop3[2]">
                <p class="badge rounded-circle bg-silver font-size-16" :class="[leaderboardTop3[2].score == leaderboardTop3[1].score ? 'bg-silver' : 'bg-bronze']">{{leaderboardTop3[2].position}}</p>
                <router-link title="View Customer" :to="{ path: `/customers/profile/${leaderboardTop3[2].customer_id}`}">
                  <h4>{{leaderboardTop3[2].first_name}} {{leaderboardTop3[2].middle_name}} {{leaderboardTop3[2].last_name}}</h4>
                </router-link>
                <p class="text-muted" v-html="formatNickname(leaderboardTop3[2].nickname)"></p>
                <h5>{{leaderboardTop3[2].score}}</h5>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-md-12 text-center" >
                <p>No data available</p>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="card-body d-flex align-items-center justify-content-center" >
              <b-spinner large></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ColumnVisibilityV2 :fields="fields" :visFields="visibleFields"></ColumnVisibilityV2>
            <!-- Table -->
            <div class="table-responsive mb-0" >
              <v-app>
                  <v-data-table
                      :headers="visibleFields"
                      :items="leaderboardData"
                      :search="filter"
                      :items-per-page="perPage"
                      :page="currentPage"
                      :server-items-length="totalItems"
                      :hide-default-footer="true"
                      @update:page="onPageChange"
                      @update:items-per-page="onItemsPerPageChange"
                      @update:sort-desc="updateSortDesc"
                      @update:sort-by="updateSortBy"
                  >

                    <template v-slot:item.first_name="{ item }">
                      <router-link title="View Customer" :to="{ path: `/customers/profile/${item.customer_id}`}">
                        {{ item.first_name + ' ' + item.middle_name + ' ' +  item.last_name }}
                      </router-link>
                    </template>

                    <template v-slot:footer>
                      <v-row v-if="showLoader" class="mx-4">
                        <v-col class="text-center">
                          <v-progress-linear indeterminate color="primary"></v-progress-linear>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col >
                          <v-pagination v-model="currentPage" :length="totalPages" :total-visible="6" @input="onPageChange" circle></v-pagination>
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:top="{ pagination, options, updateOptions }">
                      <v-row>
                        <v-col md="6" sm="12">
                          <v-data-footer :pagination="pagination" :options="options" @update:options="updateOptions" items-per-page-text="$vuetify.dataTable.itemsPerPageText" :items-per-page-options="itemsPerPageOpts"/>
                        </v-col>
                        <v-col md="6" sm="12">
                          <v-text-field v-model="filter" :append-icon="filter ? 'mdi-close' : 'mdi-magnify'" @click:append="clearSearch" label="Search" single-line hide-details outlined dense></v-text-field>
                        </v-col>
                      </v-row>
                    </template>

                  </v-data-table>
              </v-app>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- MODALS -->

    <!-- END  MODALS -->
  </Layout>
</template>
